<template>
  <b-modal v-model="showModal" centered hide-header hide-footer size="lg">
    <div class="modal-header">
      <h3 class="font-weight-bold">Preview Body</h3>
      <button type="button" aria-label="Close" class="close" @click="hide">
        ×
      </button>
    </div>
    <div class="mt-3">
      <p class="font-weight-bold">Send Body</p>
      <pre> {{ form.request }}</pre>
      <p class="font-weight-bold">Response Body</p>
      <pre> {{ form.response }}</pre>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalPreviewBody",
  components: {},
  data: function () {
    return {
      showModal: false,
      isLoading: false,
      form: {
        id: 0,
        plugin_type_id: 0,
        note: null,
        response_code: "",
        url: "",
        request: "{}",
        response: "{}",
        created_time: "",
      },
    };
  },
  methods: {
    show: async function (item = false) {
      if (item) {
        this.form = { ...item };
        try {
          this.form.request = JSON.parse(this.form.request);
          this.form.response = JSON.parse(this.form.response);
        } catch (error) {
          console.log(error);
        }
      } else this.setDefaultData();

      this.showModal = true;
    },
    setDefaultData() {
      this.form = {
        id: 0,
        plugin_type_id: 0,
        note: null,
        response_code: "",
        url: "",
        request: "{}",
        response: "{}",
        created_time: "",
      };
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.modal-header {
  /* border-bottom: 0 !important; */
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}
.modal-body {
  margin: auto;
  text-align: center;
  padding: 0;
}
.btn-modal {
  width: 100px;
  font-weight: bold;
}
</style>
