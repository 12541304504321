<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">Log</h1>
        </b-col>
      </CRow>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(createdTime)="data">
                <span>
                  {{
                    $moment(data.item.createdTime).format($formatDateTimeFull)
                  }}
                </span>
              </template>
              <template v-slot:cell(code)="data">
                <div
                  v-if="data.item.code == 'OK'"
                  class="text-success"
                >
                  สำเร็จ
                </div>
                <div
                  v-else-if="data.item.code == 'Unauthorized'"
                  class="text-danger"
                >
                  ไม่สำเร็จ
                </div>
                <div v-else class="text-danger">
                  {{ data.item.code }}
                </div>
              </template>
              <template v-slot:cell(previewBody)="data">
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="link"
                    class="px-1 py-0"
                    @click="getDatail(data.item.id)"
                  >
                    <font-awesome-icon
                      icon="search"
                      class="text-main"
                      title="View"
                    />
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerPage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalPreviewBody
      ref="ModalPreviewBody"
    />
  </div>
</template>

<script>
import ModalPreviewBody from "./components/ModalPreviewBody.vue";
export default {
  name: "SettingLogs",
  components: {
    ModalPreviewBody
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
          class: "w-100px",
        },
        {
          key: "createdTime",
          label: "วันที่สร้างรายการ",
          class: "w-100px",
        },
        {
          key: "url",
          label: "Url",
          class: "w-100px",
        },
        {
          key: "code",
          label: "Response Code",
          class: "w-100px",
        },
        {
          key: "previewBody",
          label: "Preview Body",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        PerPage: 10,
        PageNo: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  created: async function () {
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/ThirdPartyLog/list`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.count;
        this.isBusy = false;
      }
    },
    async getDatail(id){
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/ThirdPartyLog/${id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.onPreviewBody(data.detail);
      }
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerPage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    onPreviewBody(item) {
      this.$refs.ModalPreviewBody.show(item);
    },
  },
};
</script>
